import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Info from "../components/info"
import { Helmet } from "react-helmet"

class Index extends React.Component {

  render() {
    return (
      <div>
        <Appbar />
        <Info />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home | Scott</title>
        </Helmet>
      </div>
    );
  }
}

export default Index
